import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PropertiesService {
  url = 'https://onesteptax.mahi.cc/api/properties';

  /**
   * Constructor of the Service with Dependency Injection
   * @param http The standard Angular HttpClient to make requests
   */
  constructor(private http: HttpClient) { }

  /**
  * Get data from the Database
  * map the result to return only the results that we need
  *
  * @param {string} search Search Term`
  * @returns Observable with the search results
  */
  SearchProperties(search: string): Observable<any> {
    return this.http.get(`${this.url}?Search=${encodeURI(search)}`).pipe(
      map(results => results['Properties'])
    );
  }

  /**
  * Get the detailed information for an ID using the "id" parameter
  *
  * @param {string} id ID to retrieve information
  * @returns Observable with detailed information
  */
  GetProperty(Folio) {
    return this.http.get(`${this.url}?Folio=${Folio}`);
  }

  SearchSales(Vars): Observable<any> {
    return this.http.get(`${this.url}?Sales=${encodeURI(Vars.Folio)}&Distance=${encodeURI(Vars.Distance)}&LandTags=${encodeURI(Vars.LandTags)}&ToleranceBldg=${encodeURI(Vars.ToleranceBldg)}&ToleranceLand=${encodeURI(Vars.ToleranceLand)}&TolerancePrice=${encodeURI(Vars.TolerancePrice)}&ToleranceMinYear=${encodeURI(Vars.ToleranceMinYear)}&ToleranceMaxYear=${encodeURI(Vars.ToleranceMaxYear)}`).pipe(
      map(results => results['Sales'])
    );
  }

  GetValuation(Folio,Petition,FFRate,Comps,Adjust,AdjustNote,AdjustFrontage,Save): Observable<any> {
    return this.http.get(`${this.url}?Valuation=${Folio}&Petition=${Petition}&FFRate=${FFRate}&Comps=${Comps}&Adjust=${Adjust}&AdjustNote=${AdjustNote}&AdjustFrontage=${AdjustFrontage}&Save=${Save}`);
  }
}
